import React, { useState, useEffect } from "react";
import { ReservationForm } from "Molecules/Home";
import { Header, Loader, SubFooter } from "Atoms/";
import API from "Services/api";
import { Notices } from 'Molecules/';

const Index = () => {
  const [pageDetails, setPageDetails] = useState("");
  useEffect(() => {
    async function apiHit() {
      try {
        const { data } = await API.get("/parks/data", {
          params: { parkId: "61dd69adf4476d02b032ae48" },
        });
        if (data?.message === "Success") {
          setPageDetails(data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    apiHit();
  }, []);

  if (!pageDetails) {
    return <Loader />;
  }

  const {
    _id,
    aboutPark,
    parkName,
    sideImage,
    heroImage,
    aboutReservation,
    hours,
    visitorParkRates,
    parkUrl,
    aboutUrl
  } = pageDetails;
  return (
    <div>
      <Header classType="header" title1="Waiʻānapanapa" title2="State Park" url={parkUrl}>
        <Notices park="61dd69adf4476d02b032ae48" />
        <ReservationForm park={_id} url={parkUrl} entrynotice={true} />
      </Header>

      <div className="container">
        <div className="row row-padding">
          <div className="col-md-7 col-sm-7 col-xs-12">
            <h1>
              About Waiʻānapanapa
              <span className="Hide">
                <br />
              </span>
              State Park
            </h1>
            <p className="large">{aboutPark}</p>
            <p className="large">
              <a href={aboutUrl} target="_blank">
                <strong>READ MORE {">"}</strong>
              </a>
            </p>
          </div>
          <div className="offset-md-1 col-md-4 col-sm-4 col-xs-12">
            <div className="light-yellow padding-yellow">
              <h2>Park hours:</h2>
              <p>{hours}</p>
              <hr />
              <h2>Park rates:</h2>
              <p>
                Entry: {visitorParkRates[0].price} per person
                <br />
                Parking: {visitorParkRates[1].price}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="light-yellow">
        <div className="container">
          <div className="row row-padding">
            <div className="col-md-7 col-sm-7 col-xs-12">
              <h2>Reservation information</h2>
              <p style={{ whiteSpace: "pre-line" }}>{aboutReservation}</p>
            </div>
            <div className="offset-md-1 col-md-4 col-sm-4 col-xs-12">
              <img src={sideImage} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      
      <SubFooter name={parkName}  url={parkUrl} />
    </div>
  );
};

export default Index;
