import React, { useState, useEffect, useRef, useContext } from "react";
import { Quantity } from "Lib/constants";
import API from "Services/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { OrderContext } from "Lib/contexts/OrderContext";
import { useRouter } from "next/router";
import { Loader } from "Atoms/";
import { TimerContext } from "Lib/contexts/TimerContext";
import { errorObj } from "Lib/constants";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected && "#fff",
  }),
  control: () => ({
    fontFamily: "Lato",
    fontWeight: 300,
    color: "#000",
    border: "1px solid #447969",
    borderRadius: "0rem",
    backgroundColor: "#fff",
    height: "calc(1.5em + 0.95rem + 2px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  dropdownIndicator: () => ({
    marginRight: "0.5rem",
    color: "#447969",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const ReservationForm = ({park, url, entrynotice}) => {
  let router = useRouter();
  const { clearTimer, getDeadTime } = useContext(TimerContext);
  const orderCtx = useContext(OrderContext);
  const { currentOrder, setCurrentOrder } = orderCtx;
  const datepickerRef = useRef(null);
  const [formData, setFormData] = useState("");
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [bookingDate, setBookingDate] = useState("");
  const [timeSlots, setTimeSlots] = useState("");
  const [ticketType, setTicketType] = useState("-1");
  const [reservationQuantity, setReservationQuantity] = useState("");
  const [flag, setFlag] = useState(false);
  
  const [fetchActualSlots, setFetchActualSlots] = useState(false);
  const [resEntryNotice, setResEntryNotice] = useState(false);
  
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }
  
  const isSlotPresent = (slotId) => {
    for (let i = 0; i < timeSlots.length; i++) {
      if (timeSlots[i].slotId === slotId) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const getData = localStorage.getItem("customer_input");
    const inputObj = JSON.parse(getData);
    if (getData) {
      const { date, ticketId, quantity, timeslots } = inputObj;
      // console.log(moment(date).toDate());
      setTimeSlots(timeslots);
      setTicketType(ticketId);
      setBookingDate(moment(date).toDate());
      setReservationQuantity(quantity);
    }
    
    setResEntryNotice(entrynotice);
  }, []);

  useEffect(() => {
    async function apiHit() {
      try {
        const { data } = await API.get(
          "/parks/" + park + "/timeslots",
          {
            params: {
              segment: "visitor",
              ticketId: ticketType,
              quantity: reservationQuantity,
              date: moment(bookingDate).format("YYYY-MM-DD"),
            },
          }
        );

        if (data?.message === "Success") {
          const newTimeSlots = data?.data?.timeslots;
          setFormData(data?.data);
          setTimeSlotsData(newTimeSlots);
          newTimeSlots.forEach((item) => {
            if (isSlotPresent(item.slotId) && item.capacity <= 0) {
              setTimeSlots((prev) =>
                prev.filter((slot) => slot.slotId !== item.slotId)
              );
            }
          });

          if(data.status.count === 1) {
            setFetchActualSlots(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    apiHit();

    if (
      bookingDate === "" ||
      ticketType === "-1" ||
      reservationQuantity === ""
    ) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [ticketType, reservationQuantity, bookingDate, fetchActualSlots]);

  if (!formData) {
    return <Loader />;
  }
  const { ticketTypes } = formData;

  var parkandentryId;
  ticketTypes?.forEach((item) => {
    if (item.option === "Parking and entry") {
      parkandentryId = item.value;
    }
  });

  const onSubmit = async (event) => {
    const reqLocal = JSON.parse(localStorage.getItem("booking_details"));
    event.preventDefault();
    if (ticketType === -1) {
      toast("Please select the Ticket Type", errorObj);
      return;
    }
    if (!bookingDate) {
      toast("Please select the Booking date", errorObj);
      return;
    }
    if (reservationQuantity === "" || reservationQuantity === "Quantity") {
      toast("Please select the Quantity", errorObj);
      return;
    }
    if (timeSlots.length === 0) {
      toast("Please select the correct time slot(s)", errorObj);
      return;
    }
    // console.log('timeSlots', timeSlots);
    const selectedTimeSlots = timeSlots?.map((item) => ({
      slotId: item.slotId,
      label: item.label,
    }));
    try {
      if (reqLocal && reqLocal.reqID) {
        await API.post("/reservations/releasecapacity", {
          reqID: reqLocal.reqID,
        });
      }
      const obj = {
        parkId: park,
        segment: "visitor",
        ticketId: ticketType,
        quantity: reservationQuantity,
        timeslots: selectedTimeSlots,
        date: moment(bookingDate).format("YYYY-MM-DD"),
      };
      const { data } = await API.post("/reservations/fee", obj);
      if (data.message === "Success") {
        localStorage.removeItem("booking_data");
        localStorage.setItem("customer_input", JSON.stringify(obj));
        
        let bookingDetailsObj = data?.data;
        bookingDetailsObj.parkId = park;
        
        const bookingDetails = JSON.stringify(bookingDetailsObj);
        localStorage.removeItem("booking_details");
        localStorage.setItem("booking_details", bookingDetails);
        clearTimer(getDeadTime());
        router.push(url + "/reservation-details");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form method="post" onSubmit={onSubmit}>
      <div>
        <Toaster />
      </div>
      <div className="row reservation">
        <div className="col-12">
          <div className="row">
            <div className="col-12 brown-header">
              <h2>MAKE A RESERVATION</h2>
            </div>
          </div>
          <div className="row row-right-left-padding">
            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
              <select
                className="form-control"
                id="exampleFormControlSelect2"
                aria-label="Ticket type"
                onChange={(e) => {
                  setTicketType(e.target.value);
                  setTimeSlots([]);
                }}
                value={ticketType}
              >
                {ticketTypes?.map((item) => (
                  <option key={item.value} value={item.value}>{item.option}</option>
                ))}
              </select>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="input-group input-daterange">
                <DatePicker
                  id="datefield"
                  minDate={new Date(Date.now())}
                  maxDate={new Date(Date.now() + 3600 * 1000 * 24 * parseInt(process.env.NEXT_PUBLIC_RESERVATION_WINDOW?process.env.NEXT_PUBLIC_RESERVATION_WINDOW:'14'))}
                  selected={bookingDate}
                  onChange={(date) => setBookingDate(date)}
                  placeholderText="Booking Date"
                />
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2">
              <select
                className="form-control"
                id="Quantity"
                aria-label="Quantity"
                onChange={(e) => setReservationQuantity(e.target.value)}
                value={reservationQuantity}
              >
                <option value="" disabled hidden>
                  # of entries
                </option>
                {Quantity.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          
          
          
          
          
          
          
          <div className="row row-right-left-padding">
            <div className="col-12 col-lg-9">
            {fetchActualSlots &&
              <div className="input-group mb-3">
                {timeSlotsData?.map((item) => (
                  <a
                    key={item.slotId}
                    className={`btn time ${
                      (!flag || item.capacity <= 0) && "booked"
                    }`}
                    style={{
                      backgroundColor: isSlotPresent(item.slotId) && "#396a5b",
                      color: isSlotPresent(item.slotId) && "#fff",
                      pointerEvents: item.capacity <= 0 ? "none" : "auto",
                    }}
                    // disbaled={bookingDate === '' ? true : false}
                    onClick={() => {
                      if (
                        ticketType === "-1" ||
                        reservationQuantity === "" ||
                        reservationQuantity === "Quantity"
                      ) {
                        toast("All Fields are required", errorObj);
                        return;
                      }
                      if (isSlotPresent(item.slotId)) {
                        setTimeSlots(
                          timeSlots.filter(
                            (slot) => slot.slotId !== item.slotId
                          )
                        );
                      } else {
                        if (timeSlots.length === 0) {
                          setTimeSlots([item]);
                        } else if (parkandentryId === ticketType) {
                          if (timeSlots.length === 1) {
                            if (
                              Number(item.slotId) ===
                                Number(timeSlots[0].slotId) + 1 ||
                              Number(item.slotId) ===
                                Number(timeSlots[0].slotId) - 1
                            ) {
                              setTimeSlots((prev) => [...prev, item]);
                            } else {
                              toast(
                                "Please select the correct time slot(s)",
                                errorObj
                              );
                            }
                          } else {
                            toast("Select only two time slots.", errorObj);
                          }
                        } else {
                          toast("Select only one time slot.", errorObj);
                        }
                      }
                    }}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            }
            </div>
            <div className="col-12 col-lg-3 text-left text-lg-right">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                onClick={() => localStorage.setItem("timer", 900)}
              >
                Let's Go
              </button>
            </div>
          </div>
          
          
          
          
          
          
          <div className="row row-right-left-padding">
            <div className="col-12">            
              {resEntryNotice &&
                <span className="red" style={{ fontSize: "23px" }}>
                  <span style={{ fontWeight: "bold" }}>Entry only tickets</span> are to be purchased only for guests accompanied by a Hawaiʻi resident.
                  <br />
                  <span style={{ fontWeight: "bold" }}>Parking and entry tickets</span> are to be purchased by all non-residents. Each parking voucher is for 1 vehicle, and the number of entries is for the total number of passengers in the vehicle.
                  <br />
                  <br />
                </span>
              }
              {!resEntryNotice &&
                <span className="red" style={{ fontSize: "23px", fontWeight: "bold"}}>
                  For Parking, vehicles must arrive within the first 30 minutes of
                  your reservation period.
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ReservationForm;
